<template>
	<v-container class="mt-4 px-5" fluid>
	  <v-row v-if="( [18,19].includes( getdatosUsuario.idpuesto ) && [568,626,7,1312,161,1248,1196,1489,1312,28,1778].includes( getdatosUsuario.iderp ) ) || ![18,19].includes( getdatosUsuario.idpuesto )">
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  			<v-card-title primary-title>
	  		    <span class="text-subtitle-1">Visualizar entradas </span>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              dark
              class="mr-2"
              @click="dialogVacantes =true"
              small
              tile
            >
              Vacantes
            </v-btn>

	  		    <v-btn
              color="primary"
              dark
              @click="initialize"
              small
              tile
            >
              Actualizar
            </v-btn>
            
	  		  </v-card-title>
          
          <v-card-text>
            <v-row justify="end">
              <v-col cols="12" md="6">
                <v-text-field
                  label="Buscar"
                  dense
                  filled
                  append-icon="mdi-magnify"
                  v-model="search"
                  class="ms-4"
                ></v-text-field>
              </v-col>
            </v-row>
  				  <v-data-table
  				    :headers="headers"
  				    :items="empleados"
  				    class="elevation-0"
              :search="search"
              dense
              :items-per-page="empleados.length"
              hide-default-footer
  				  >
              <template v-slot:item.coordi="{ item }">
                <v-chip small v-for="( coordi, i ) in item.coordi" 
                	:key="i" :color="coordi.asistencia == 1 ? 'green' : 'red'"
                  @click="(encargadas_telefonos = [coordi]),(reemplazoAux = coordi.reemplazo), (dialogTelefonos = true)"
                	dark>
                	  <v-icon left v-if="coordi.asistencia == 1">mdi-circle-medium</v-icon>
                    <v-icon left v-if="coordi.reemplazo == 1">mdi-skull</v-icon>
                	  {{ coordi.nombre_usuario }} 
                </v-chip>
                <v-chip v-if="item.coordiCant == 0" small color="white"> 
              		N/A
              	</v-chip>
                <v-chip v-else-if="!item.coordi.length" small @click="dialogAddVacante( item, 'ZONA' )"> 
              		VACANTE 
              	</v-chip>
              </template>

              <template v-slot:item.vendedoras="{ item }">
                <v-chip small v-for="( vendedora, i ) in item.vendedoras" 
                	:key="i" :color="vendedora.asistencia == 1 ? 'green' : 'red'" 
                	dark @click="(encargadas_telefonos = [vendedora]),(reemplazoAux = vendedora.reemplazo), (dialogTelefonos = true)">
                	  <v-icon left v-if="vendedora.asistencia == 1">mdi-circle-medium</v-icon>
                    <v-icon left v-if="vendedora.reemplazo == 1">mdi-skull</v-icon> 
                	  {{ vendedora.nombre_usuario }} 
                </v-chip>
                <v-chip v-if="item.vendedorasCant == 0" small color="white"> 
              		N/A
              	</v-chip>
                <v-chip v-else-if="!item.vendedoras.length" small @click="dialogAddVacante( item, 'VENDEDORA' )"> 
              		VACANTE 
              	</v-chip>
              </template>

              <template v-slot:item.intendencia="{ item }">
                <v-chip small v-for="( intendencia, i ) in item.intendencia" 
                	:key="i" :color="intendencia.asistencia == 1 ? 'green' : 'red'" 
                  @click="(encargadas_telefonos = [intendencia]),(reemplazoAux = intendencia.reemplazo), (dialogTelefonos = true)"
                	dark>
                	  <v-icon left v-if="intendencia.asistencia == 1">mdi-circle-medium</v-icon>
                    <v-icon left v-if="intendencia.reemplazo == 1">mdi-skull</v-icon> 
                	  {{ intendencia.nombre_usuario }} 
                </v-chip>
                <v-chip v-if="item.intendenciaCant == 0" small color="white"> 
              		N/A
              	</v-chip>
                <v-chip v-else-if="!item.intendencia.length" small @click="dialogAddVacante( item , 'INTENDENCIA' )"> 
              		VACANTE 
              	</v-chip>
              </template>

  				    <template v-slot:item.encargadas="{ item }">
                <v-chip 
                	small 
                	v-for="( encargada, i ) in item.encargadas" 
                	:key="i" 
                	:color="encargada.asistencia == 1 ? 'green' : 'red'" 
                	dark
                  @click="(encargadas_telefonos = [encargada]),(reemplazoAux = encargada.reemplazo), (dialogTelefonos = true)"
                >
                	<v-icon left v-if="encargada.asistencia == 1">mdi-circle-medium</v-icon>
                  <v-icon left v-if="encargada.reemplazo == 1">mdi-skull</v-icon>
                	{{ encargada.nombre_usuario }} 
                </v-chip>
                <v-chip v-if="item.encargadasCant == 0" small color="white"> 
              		N/A
              	</v-chip>
                <v-chip v-else-if="!item.encargadas.length" small @click="dialogAddVacante( item, 'ENCARGADA' )"> 
              		VACANTE 
              	</v-chip>
              </template>
              <template v-slot:item.recepcionistaMat="{ item }">
                <v-chip small
                	v-for="( recepcionista, i ) in item.recepcionistaMat" 
                	:key="i" 
                	:color="recepcionista.asistencia == 1 ? 'green' : 'red'" 
                	dark
                  @click="(encargadas_telefonos = [recepcionista]),(reemplazoAux = recepcionista.reemplazo), (dialogTelefonos = true)"
                > 
                	<v-icon left v-if="recepcionista.asistencia == 1">mdi-circle-medium</v-icon>
                  <v-icon left v-if="recepcionista.reemplazo == 1">mdi-skull</v-icon> 
                	{{ recepcionista.nombre_usuario }} 
                </v-chip>
                <v-chip v-if="item.recepcionistaMatCant == 0" small color="white"> 
              		N/A
              	</v-chip>
                <v-chip v-else-if="!item.recepcionistaMat.length" small @click="dialogAddVacante( item, 'RECEP1' )"> 
              		VACANTE 
              	</v-chip>
              </template>
              <template v-slot:item.recepcionistaVes="{ item }">
                <v-chip small v-if="item.recepcionistaVes.length"
                	:color="item.recepcionistaVes[0].asistencia == 1 ? 'green' : 'red'" dark
                  @click="(encargadas_telefonos = [item.recepcionistaVes[0]]),(reemplazoAux = item.recepcionistaVes[0].reemplazo), (dialogTelefonos = true)"
                > 
                	<v-icon left v-if="item.recepcionistaVes[0].asistencia == 1">mdi-circle-medium</v-icon>
                  <v-icon left v-if="item.recepcionistaVes[0].reemplazo == 1">mdi-skull</v-icon> 
                	{{ item.recepcionistaVes[0].nombre_usuario }} 
                </v-chip>
                <v-chip v-else-if="item.recepcionistaVesCant == 0" small color="white"> 
              		NA
              	</v-chip>
                <v-chip v-else-if="!item.recepcionistaVes.length" small @click="dialogAddVacante( item, 'RECEP2' )"> 
              		VACANTE 
              	</v-chip>
              </template>
              <template v-slot:item.recepcionistaVes2="{ item }">
                <v-chip small v-if="item.recepcionistaVes.length == 2"
                	:color="item.recepcionistaVes[1].asistencia == 1 ? 'green' : 'red'" dark
                  @click="(encargadas_telefonos = [item.recepcionistaVes[1]]),(reemplazoAux = item.recepcionistaVes[1].reemplazo), (dialogTelefonos = true)"
                > 
                	<v-icon left v-if="item.recepcionistaVes[1].asistencia == 1">mdi-circle-medium</v-icon>
                  <v-icon left v-if="item.recepcionistaVes[1].reemplazo == 1">mdi-skull</v-icon> 
                	{{ item.recepcionistaVes[1].nombre_usuario }} 
                </v-chip>
                <v-chip v-else-if="item.recepcionistaVesCant <= 1" small color="white"> 
              		NA
              	</v-chip>
                <v-chip v-else small @click="dialogAddVacante( item , 'RECEP3')"> 
              		VACANTE 
              	</v-chip>
              </template>

  				    <template v-slot:no-data>
  				      <v-btn
  				        color="primary"
  				        @click="initialize"
  				        small
  				      >
  				        Actualizar
  				      </v-btn>
  				    </template>
  				  </v-data-table>
              
          </v-card-text>

				</v-card>
		  </v-col>
      

    <v-dialog v-model="dialogTelefonos"max-width="900">
      <v-card>
        <v-card-title primary-title >
          <v-icon left>mdi-phone</v-icon> Telefonos
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text >
          <v-data-table
            :headers="headersTelefonos"
            :items="encargadas_telefonos"
            class="elevation-0"
            dense
          > 
          <template v-slot:item.nombre_completo="{ item }">
            <v-row align="center">
              <p class="ma-0 me-5">{{ item.nombre_completo }}</p>
              <v-checkbox
              v-model="reemplazoAux"
              :label="`Reemplazo`"
              v-if="getdatosUsuario.idpuesto != 31"
              ></v-checkbox>
            </v-row>
          </template>           
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error"   dark @click="dialogTelefonos = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" v-if="getdatosUsuario.idpuesto != 31"  dark @click="(encargadas_telefonos[0].reemplazo = reemplazoAux), actualizarPuesto(encargadas_telefonos)">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogVacante"max-width="500">
      <v-card>
        <v-card-title primary-title >
          Datos Vacante
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text v-if="dataVancante">

          <b>Puesto:</b> {{ dataVancante.puesto }}
          <br/>
          <b>Plantel: </b>{{ dataVancante.plantel }}
          <br/>
          <b>Fecha de solicitud: </b>{{ dataVancante.fecha }}
          <br/>
          <b>Tiempo transcurrido: </b>{{ dataVancante.dias }}

        </v-card-text>
        <v-card-actions v-if="dataVancante">
          <v-btn 
            color="black"   
            dark 
            small
            tile
            @click="dialogVacante = false"
          >
            <v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn 
            color="green"   
            dark 
            small
            tile
            @click="addSolicitud()"
            v-if="!dataVancante.id"
          >
            Solicitar
            <v-icon small right>mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogVacantes" max-width="900">
      <v-card>
        <v-card-title primary-title >
          <!-- <v-icon left>mdi-</v-icon>--> Vacantes 
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text >
          <v-row>
            <v-col>
              <div>
                <p class="ma-0"><strong>Ultimo cambio realizado:</strong></p>
                <p class="ma-0">Usuario: {{ this.ultimoCambio[0].nombre_completo }}</p>
                <p>Fecha: {{ this.ultimoCambio[0].fecha_ultimo_cambio }}</p>
              </div>
            </v-col>
          </v-row>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                </th>
                <th class="text-left" style="background-color: rgb(226, 233, 247);">
                  INBI
                </th>
                <th class="text-left"  style="background-color: rgb(236, 213, 213);">
                  FAST
                </th>
                <th class="text-left">
                  TOTAL
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Total que ocupamos:</td>
                <td>{{ colaboradoresTotales.totalesOcupamosINBI }}</td>
                <td>{{ colaboradoresTotales.totalesOcupamosFAST }}</td>
                <td>{{ colaboradoresTotales.totalesOcupamosINBI + colaboradoresTotales.totalesOcupamosFAST }}</td>
              </tr>
              <tr >
                <td>Total de colaboradores:</td>
                <td>{{ colaboradoresTotales.totalesINBI }}</td>
                <td>{{ colaboradoresTotales.totalesFAST }}</td>
                <td>{{ colaboradoresTotales.totalesINBI + colaboradoresTotales.totalesFAST }}</td>
              </tr>
              <tr>
                <td>Vacantes por cubrir:</td>
                <td>{{ colaboradoresTotales.totalesVacantesCubrirINBI }}</td>
                <td>{{ colaboradoresTotales.totalesVacantesCubrirFAST }}</td>
                <td>{{ colaboradoresTotales.totalesVacantesCubrirINBI + colaboradoresTotales.totalesVacantesCubrirFAST }}</td>
              </tr>
              <tr>
                <td>Reemplazos:</td>
                <td>{{ colaboradoresTotales.totalesReemplazosINBI }}</td>
                <td>{{ colaboradoresTotales.totalesReemplazosFAST }}</td>
                <td>{{ colaboradoresTotales.totalesReemplazosINBI + colaboradoresTotales.totalesReemplazosFAST }}</td>
              </tr>
              <tr>
                <td>Vacantes totales:</td>
                <td>{{ colaboradoresTotales.totalesVacantesINBI }}</td>
                <td>{{ colaboradoresTotales.totalesVacantesFAST }}</td>
                <td>{{ colaboradoresTotales.totalesVacantesINBI + colaboradoresTotales.totalesVacantesFAST }}</td>
              </tr>
              
            </tbody>
          </template>
        </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error"   dark @click="dialogVacantes = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

	  </v-row>

	  <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
  import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'
  import funcionesExcel  from '@/mixins/funcionesExcel'


  export default {

  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      reemplazoAux: 0,

      dialog: false,
      dialogDelete: false,
      dialogVacantes:false,
      dialogTelefonos: false,
      encargadas_telefonos : [],
      colaboradoresTotales : [],
      puestosTotales : [],
      ultimoCambio : '',
      headers: [
        { text: 'Plantel',                    value: 'plantel', },
        { text: 'Coordinador de zona',        value: 'coordi', },
        { text: 'Encargada',                  value: 'encargadas', },
        { text: 'Vendedora',                  value: 'vendedoras' },
        { text: 'Recepcionista Matutina',     value: 'recepcionistaMat', },
        { text: 'Recepcionista Vespertina',   value: 'recepcionistaVes', },
        { text: '2da Recepcionista Vespertina',value: 'recepcionistaVes2', },
        { text: 'Intendencia',                value: 'intendencia' },
      ],
      headersTelefonos: [
        { text: 'Nombre'                    , value: 'nombre_completo' },
        { text: 'Telefono1'                 , value: 'telefono1' },
        { text: 'Telefono2'                 , value: 'telefono2' },
      ],
      empleados: [],
      search:'',

      payload:{
        totalesINBI:0,
        totalesFAST:0,
        totalesVacantesCubrirINBI:0,
        totalesVacantesCubrirFAST:0,
        totalesOcupamosINBI:0,
        totalesOcupamosFAST:0,
        totalesReemplazosINBI:0,
        totalesReemplazosFAST:0,
        totalesVacantesINBI:0,
        totalesVacantesFAST:0,
      },

      dataVancante: null,
      dialogVacante: false
    }),

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    async created () {
      console.log( 'gohohpi',this.getdatosUsuario )
      await this.initialize()
    },

    computed: {
      ...mapGetters("login", ["getdatosUsuario", "getLogeado"]),
    },

    methods: {
      initialize () {
        this.empleados = []
        this.cargar = true


        this.colaboradoresTotales = Object.assign({}, this.payload )
        this.$http.get('puestos.plantel').then(response=>{
          this.puestosTotales = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { })

        this.$http.get('trabajador.ultimocambio').then(response=>{
          this.ultimoCambio = response.data
          console.log(this.ultimoCambio)
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { })

        return this.$http.get('asistencias.visuales').then(response=>{
        	this.empleados = response.data
          this.empleados.forEach(element => {
            if(element.plantel.includes('FAST')){
              this.colaboradoresTotales.totalesFAST = this.colaboradoresTotales.totalesFAST + element.encargadas.length + element.vendedoras.length + 
              element.coordi.length + element.recepcionistaMat.length + element.recepcionistaVes.length
              + element.intendencia.length;
              element.coordi.forEach(trabajador => {
                this.colaboradoresTotales.totalesReemplazosFAST = trabajador.reemplazo ? this.colaboradoresTotales.totalesReemplazosFAST + 1 : this.colaboradoresTotales.totalesReemplazosFAST
              });
              element.encargadas.forEach(trabajador => {
                this.colaboradoresTotales.totalesReemplazosFAST = trabajador.reemplazo ? this.colaboradoresTotales.totalesReemplazosFAST + 1 : this.colaboradoresTotales.totalesReemplazosFAST
              });
              element.vendedoras.forEach(trabajador => {
                this.colaboradoresTotales.totalesReemplazosFAST = trabajador.reemplazo ? this.colaboradoresTotales.totalesReemplazosFAST + 1 : this.colaboradoresTotales.totalesReemplazosFAST
              });
              element.recepcionistaMat.forEach(trabajador => {
                this.colaboradoresTotales.totalesReemplazosFAST = trabajador.reemplazo ? this.colaboradoresTotales.totalesReemplazosFAST + 1 : this.colaboradoresTotales.totalesReemplazosFAST
              });
              element.recepcionistaVes.forEach(trabajador => {
                this.colaboradoresTotales.totalesReemplazosFAST = trabajador.reemplazo ? this.colaboradoresTotales.totalesReemplazosFAST + 1 : this.colaboradoresTotales.totalesReemplazosFAST
              });
              element.intendencia.forEach(trabajador => {
                this.colaboradoresTotales.totalesReemplazosFAST = trabajador.reemplazo ? this.colaboradoresTotales.totalesReemplazosFAST + 1 : this.colaboradoresTotales.totalesReemplazosFAST
              });
              
              this.puestosTotales.forEach(element2 => {
                if(element2.idplantel == element.idplantel){
                  this.colaboradoresTotales.totalesOcupamosFAST = this.colaboradoresTotales.totalesOcupamosFAST + element2.Cantidad
                }
              });

            } else{
               this.colaboradoresTotales.totalesINBI =this.colaboradoresTotales.totalesINBI + element.encargadas.length + element.vendedoras.length + 
               element.coordi.length + element.recepcionistaMat.length + element.recepcionistaVes.length
               + element.intendencia.length;

               element.coordi.forEach(trabajador => {
                this.colaboradoresTotales.totalesReemplazosINBI = trabajador.reemplazo ? this.colaboradoresTotales.totalesReemplazosINBI + 1 : this.colaboradoresTotales.totalesReemplazosINBI
              });
              element.encargadas.forEach(trabajador => {
                this.colaboradoresTotales.totalesReemplazosINBI = trabajador.reemplazo ? this.colaboradoresTotales.totalesReemplazosINBI + 1 : this.colaboradoresTotales.totalesReemplazosINBI
              });
              element.vendedoras.forEach(trabajador => {
                this.colaboradoresTotales.totalesReemplazosINBI = trabajador.reemplazo ? this.colaboradoresTotales.totalesReemplazosINBI + 1 : this.colaboradoresTotales.totalesReemplazosINBI
              });
              element.recepcionistaMat.forEach(trabajador => {
                this.colaboradoresTotales.totalesReemplazosINBI = trabajador.reemplazo ? this.colaboradoresTotales.totalesReemplazosINBI + 1 : this.colaboradoresTotales.totalesReemplazosINBI
              });
              element.recepcionistaVes.forEach(trabajador => {
                this.colaboradoresTotales.totalesReemplazosINBI = trabajador.reemplazo ? this.colaboradoresTotales.totalesReemplazosINBI + 1 : this.colaboradoresTotales.totalesReemplazosINBI
              });
              element.intendencia.forEach(trabajador => {
                this.colaboradoresTotales.totalesReemplazosINBI = trabajador.reemplazo ? this.colaboradoresTotales.totalesReemplazosINBI + 1 : this.colaboradoresTotales.totalesReemplazosINBI
              });

               this.puestosTotales.forEach(element2 => {
                if(element2.idplantel == element.idplantel){
                  this.colaboradoresTotales.totalesOcupamosINBI = this.colaboradoresTotales.totalesOcupamosINBI + element2.Cantidad
                }
              });
            }
          });
          this.colaboradoresTotales.totalesVacantesCubrirINBI = this.colaboradoresTotales.totalesOcupamosINBI - this.colaboradoresTotales.totalesINBI
          this.colaboradoresTotales.totalesVacantesCubrirFAST = this.colaboradoresTotales.totalesOcupamosFAST - this.colaboradoresTotales.totalesFAST
          this.colaboradoresTotales.totalesVacantesINBI = this.colaboradoresTotales.totalesVacantesCubrirINBI + this.colaboradoresTotales.totalesReemplazosINBI
          this.colaboradoresTotales.totalesVacantesFAST = this.colaboradoresTotales.totalesVacantesCubrirFAST + this.colaboradoresTotales.totalesReemplazosFAST
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      actualizarPuesto(item){
        this.cargar = true;
         this.$http.put('trabajador.reemplazo/'+item[0].reemplazo +'/' +item[0].id_trabajador+'/'+this.getdatosUsuario.iderp).then(response=>{
          this.initialize()
          this.validarSuccess ( response.data )
         }).catch( error =>{
           this.validarError( error.response.data.message )
         }).finally( () => { this.cargar = false })
      },

      validarSuccess( mensaje ){
        this.cargar = false
        this.parametros.mensaje      = mensaje
        this.parametros.dialogError  = true 
        this.parametros.color        = 'success'
        this.respuestaAlerta         = true 
      },

      editItem (item) {

      	this.alumno = item

       	if( item.escuela == 2 )
       		this.urlDirecta = this.urlFast + item.nombre_video
       	else
       		this.urlDirecta = this.urlInbi + item.nombre_video

        this.dialog = true
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      dialogAddVacante( item, puesto ){
        this.dataVancante = {
          idplantel: item.idplantel,
          plantel: item.plantel,
          puesto
        }

        let payload = {
          idplantel: this.dataVancante.idplantel,
          plantel  : this.dataVancante.plantel,
          puesto   : this.dataVancante.puesto,
          solicito: this.getdatosUsuario.iderp
        }

        this.cargar = true;
        this.$http.post('vacante.get', payload ).then(response=>{

          console.log( response.data )

          if( response.data ){
            this.dataVancante['dias']  = response.data.dias
            this.dataVancante['fecha'] = response.data.fecha_creacion
            this.dataVancante['id']    = response.data.idvacante_rh
          }

          this.dialogVacante = true
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

        // Ver si hay una solicitud


      },

      addSolicitud( ){
        let payload = {
          idplantel: this.dataVancante.idplantel,
          plantel: this.dataVancante.plantel,
          puesto : this.dataVancante.puesto,
          solicito: this.getdatosUsuario.iderp
        }

        this.cargar = true;
        this.$http.post('vacante.add', payload ).then(response=>{
          this.dialogVacante = false
          this.initialize()
          this.validarSuccess ( response.data.message )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      }
    },
  }
  /*454*/
</script>